/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/jsx-key */
import React, { useState, useLayoutEffect, useEffect } from 'react'
import { Link, graphql, navigate } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

import styled from 'styled-components'
import { theme } from 'styled-tools'

import { BreadcrumbAuto, MainContainer, Div, Pagination } from '@monorepo-amais/commons/components/Blocks'
import { MainTitle } from '@monorepo-amais/commons/components/Typography'
import PDF from '@monorepo-amais/commons/images/pdf.png'

import { formataDataCosmic } from '@monorepo-amais/commons/utils/date'
import toSlug from '@monorepo-amais/commons/utils/slugify'
import { setClientMedic } from '@monorepo-amais/commons/utils/sessionControl'
import imgixUrlOptimizerAuto from '@monorepo-amais/commons/utils/imgixUrlOptimizerAuto'

const Subtitle = styled.div`
	margin: 10px 0px;
`

const PDFIcon = styled.img`
	height: 22px;
	object-fit: contain;
	margin: 0px 10px 0px 0px;
	@media (max-width: 1023.98px) {
		height: 16px;
	}
`

const SubtitleText = styled.span`
	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.19;
	letter-spacing: normal;
	text-align: left;
	color: ${props => props.theme.colors.gray66};
`

const BoletinHeader = styled.div`
	background-color: ${props => props.theme.colors.primary};
	width: 100%;
	display: inline-block;
`

const BoletinHeaderContent = styled.div`
	padding: 16px 20px;
	display: flex;
	align-items: center;
	/* width: 97%; */

	@media (max-width: 1023.98px) {
		display: flex;
		padding: 10px;
	}
	@media (max-width: 280px) {
		display: block;
	}
`

const Pipe = styled.div`
	width: 0;
	height: 28px;
	border: solid 1px ${theme('colors.white')};
	display: inline-block;
	margin: 0px 20px;

	@media (max-width: 1023.98px) {
		display: none;
	}
`

const BoletinHeaderText = styled.span`
	font-size: 28px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.32;
	letter-spacing: normal;
	text-align: left;
	color: ${theme('colors.white')};
	white-space: nowrap;

	@media (max-width: 1023.98px) {
		display: block;
		font-size: 12px;
	}
`

const BoletinHeaderPDFText = styled.span`
	font-size: 20px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.3;
	letter-spacing: normal;
	text-align: left;
	color: ${theme('colors.white')};
	@media (max-width: 1023.98px) {
		font-size: 12px;
	}
`

const BoletinHighlight = styled.span`
	font-size: 26px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.35;
	letter-spacing: normal;
	text-align: left;
	color: ${props => props.theme.colors.black_fonts};

	@media (max-width: 1023.98px) {
		font-size: 20px;
	}
`

const Underline = styled.div`
	color: ${props => props.theme.colors.black33};
	height: 5px;
	width: 90px;
	margin-top: 5px;
	background: ${props => props.theme.colors.primary};
`

const HeaderPDF = styled.div`
	display: block;
	float: right;
	width: 100%;
	/*Device para Galaxy Fold */
	@media (max-width: 280px) {
		float: left;
		width: 40%;
		margin: 5px 0 10px 0;
	}
`
const HeaderPDFRigth = styled.div`
	display: flex;
	float: right;
	align-items: center;
`

const BoletinContent = styled.div`
	border: solid 1px #e2e2e2;
	background-color: ${theme('colors.white')};
	width: 100%;
	margin: ${props => (props.index === 0 ? null : props.differentMargin ? '20px 0px 0px' : '80px 0px 0px')};

	@media (max-width: 1023.98px) {
		margin: ${props => (props.index === 0 ? null : props.differentMargin ? '20px 0px 0px' : '40px 0px 0px')};
	}
`

const HighlightContainer = styled.div`
	margin: 40px 20px 0px;

	@media (max-width: 1023.98px) {
		margin: 20px 10px 0px;
	}
`

const BoletinBody = styled.div``

const HighlightThumb = styled.img`
	width: 100%;
	height: 150px;
	object-fit: cover;
	border-radius: 3px;
	margin: 0px 0px 20px;
`
const HighlightItem = styled.div`
	display: inline-block;
	margin: 20px 0px 40px;
	padding: 0px 2% 0px;
	border-top: none;
	border-left: ${props => (props.index === 0 ? null : 'solid 1px #e2e2e2')};
	border-width: 1px;

	@media (max-width: 1023.98px) {
		width: calc(100% - 20px);
		padding: 20px 0px 0px;
		margin: 0px 10px 20px;
		border-left: none;
		border-top: ${props => (props.index === 0 ? null : 'solid 1px #e2e2e2')};
	}
`

const HighlightTitle = styled.span`
	font-size: 22px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.32;
	letter-spacing: normal;
	text-align: left;
	color: ${props => props.theme.colors.black_fonts};

	@media (max-width: 1023.98px) {
		font-size: 20px;
	}
`

const HighlightText = styled.span`
	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.19;
	letter-spacing: normal;
	text-align: left;
	color: ${props => props.theme.colors.gray66};
`

const HighlightTitleContainer = styled.div`
	margin: 0px 0px 20px;
`

const PaginationContainer = styled.div`
	margin: 40px 0px 0px;
`

const Background = styled.div`
	background-color: ${props => (props.page === 1 ? '#f8f8f8' : '')};
`

const DestaquesContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	@media (max-width: 1023.98px) {
		grid-template-columns: 1fr;
	}
`
const ListaBoletins = ({ data }) => {
	const [page, setPage] = useState(1)
	const [totalPages, setTotalPages] = useState(0)
	const [boletinPage, setBoletinPage] = useState([])
	const [firstPage, setFirstPage] = useState([])
	const [boletinList, setBoletinList] = useState([])
	const PAGE_LIMIT = 5

	useEffect(() => {
		navigate('/404')
	}, [])

	setClientMedic('medico')

	useLayoutEffect(() => {
		const offset = (page - 1) * PAGE_LIMIT + 1
		const cut = offset + PAGE_LIMIT
		setBoletinPage(boletinList.slice(offset, cut))
		setFirstPage(boletinList.slice(0, 1))
	}, [boletinList, page])

	const handleChangePage = page => {
		const offset = (page - 1) * PAGE_LIMIT + 1
		const cut = offset + PAGE_LIMIT
		setPage(page)
		setBoletinPage(boletinList.slice(offset, cut))
	}

	if (boletinList.length === 0) {
		const boletinsOrdened = data.allCosmicjsBoletinsMedicos.nodes.sort((a, b) => {
			let dateA = new Date(a.metadata.data)
			let dateB = new Date(b.metadata.data)
			return dateB - dateA
		})

		const boletins = boletinsOrdened.map((boletin, index) => {
			let formatedDate = formataDataCosmic(boletin.metadata.data, true)

			const destaques = boletin.metadata.destaques.map((destaque, index) => {
				const url = `/boletim-medico/${toSlug(destaque.titulo)}`
				if (index < 3) {
					return (
						<Link to={url}>
							<HighlightItem index={index}>
								<HighlightThumb src={imgixUrlOptimizerAuto(destaque.thumbnail.imgix_url)}></HighlightThumb>
								<HighlightTitleContainer>
									<HighlightTitle>{destaque.titulo}</HighlightTitle>
									<Underline />
								</HighlightTitleContainer>
								<HighlightText>{destaque.subtitulo}</HighlightText>
							</HighlightItem>
						</Link>
					)
				} else {
					return null
				}
			})
			return (
				<div>
					{index === 0 && (
						<Div>
							<MainTitle>{data.boletins.metadata.titulo}</MainTitle>
							<Subtitle>
								<SubtitleText>{data.boletins.metadata.descricao}</SubtitleText>
							</Subtitle>
						</Div>
					)}
					{(index - 1) % PAGE_LIMIT === 0 && (
						<Div>
							<MainTitle>{data.boletins.metadata.titulo_edicoes}</MainTitle>
						</Div>
					)}
					<BoletinContent index={index} differentMargin={(index - 1) % PAGE_LIMIT === 0}>
						<BoletinHeader>
							<BoletinHeaderContent>
								<BoletinHeaderText>{data.boletins.metadata.nome_secao}</BoletinHeaderText>
								<Pipe></Pipe>
								<BoletinHeaderText>
									Edição {boletin.metadata.edicao} • ano {boletin.metadata.ano} • {formatedDate}
								</BoletinHeaderText>
								<HeaderPDF>
									<a href={boletin.metadata.pdf.url} target='_blank' download='proposed_file_name'>
										<HeaderPDFRigth>
											<PDFIcon src={PDF}></PDFIcon>
											<BoletinHeaderPDFText>{data.boletins.metadata.titulo_botao}</BoletinHeaderPDFText>
										</HeaderPDFRigth>
									</a>
								</HeaderPDF>
							</BoletinHeaderContent>
						</BoletinHeader>
						<BoletinBody>
							<HighlightContainer>
								<BoletinHighlight>{data.boletins.metadata.texto_destaques}</BoletinHighlight>
								<Underline />
							</HighlightContainer>
							<DestaquesContainer>{destaques}</DestaquesContainer>
						</BoletinBody>
					</BoletinContent>
				</div>
			)
		})

		const pages = Math.ceil(boletins.length / PAGE_LIMIT)
		setTotalPages(pages % parseInt(pages) > 0 ? parseInt(pages) + 1 : pages)
		setBoletinList(boletins)
	}

	return (
		<Layout>
			<SEO
				title={data.boletins.metadata.seo.titulo}
				description={data.boletins.metadata.seo.descricao}
				image={imgixUrlOptimizerAuto(data.boletins.metadata.seo.imagem.imgix_url)}
			/>

			<MainContainer needPaddingZeroMax1024={false}>
				<BreadcrumbAuto title={data.boletins.metadata.titulo} />

				{page === 1 && firstPage}
			</MainContainer>
			<Background page={page}>
				<MainContainer needPaddingZeroMax1024={false}>
					<div>{boletinPage}</div>
					<PaginationContainer>
						<Pagination
							gridColumn='1 / -1'
							elementsPerPage={PAGE_LIMIT}
							totalPages={totalPages}
							handleNextPage={handleChangePage}
						/>
					</PaginationContainer>
				</MainContainer>
			</Background>
		</Layout>
	)
}

export const pageQuery = graphql`
	query {
		boletins: cosmicjsInformacoesEstaticas(title: { eq: "Boletim Medico" }) {
			metadata {
				titulo
				descricao
				texto_destaques
				texto_compartilhamento
				titulo_botao
				nome_secao
				titulo_edicoes
				seo {
					descricao
					imagem_descricao
					titulo
					imagem {
						imgix_url
					}
				}
			}
		}
		allCosmicjsBoletinsMedicos {
			nodes {
				metadata {
					data
					ano
					edicao
					destaques {
						titulo
						subtitulo
						seo {
							titulo
							descricao
							imagem {
								imgix_url
							}
						}
						conteudo
						thumbnail {
							url
							imgix_url
						}
					}
					pdf {
						url
					}
				}
			}
		}
	}
`

export default ListaBoletins
